import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Navbar.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logoImage from "./logo.png";
import Select from "react-select";


function SiteNavbar() {
  const [searchTerm, setSearchTerm] = useState("");
  const [predefinedItems, setPredefinedItems] = useState([]);
  const navigate = useNavigate();
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const fetchPredefinedItems = async () => {
      try {
        const response = await axios.get(
          "https://osrsprices.com/api/itemNames",
        );
        const data = response.data; 
        const itemNames = data.results.map((item) => ({
          label: item.itemName,
          value: item.itemName,
        }));
        setPredefinedItems(itemNames);
      } catch (error) {
        console.error("Error fetching predefined items:", error);
      }
    };

    fetchPredefinedItems();
  }, []);

  useEffect(() => {
    const img = new Image();
    img.src = logoImage;
  }, []);

  const handleInputChange = (selectedOption) => {

    if (selectedOption) {
      const url = `/item/${encodeURIComponent(selectedOption.label)}`;
      window.location.href = url;
    }
  };


  const filteredItems =
  searchTerm.trim().length >= 3
    ? predefinedItems.filter((item) =>
        item.label.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="bg-body-tertiary"
      bg="dark"
      data-bs-theme="dark"
    >
      <Container fluid>
        <a href="/" className="nav-links">
          <img
            src={logoImage}
            alt="Logo"
            height="55"
            width="135"
            className="d-inline-block align-top"
            style={{ paddingLeft: "10px", paddingBottom: "10px" }}
            loading="lazy"
          />
        </a>

        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={handleDropdownToggle}
          style={{ marginLeft: "10" }}
        />

        <Navbar.Collapse
          id="responsive-navbar-nav"
          className={isDropdownOpen ? "show" : ""}
        >
          <Nav className="mx-auto my-2 my-lg-0">
            <Nav.Link href="/" className="nav-links">
              Home
            </Nav.Link>
            <Nav.Link href="/HighMargins" className="nav-links">
              High Profit Margins
            </Nav.Link>
            <Nav.Link href="/HighVolume" className="nav-links">
              High Volume
            </Nav.Link>
            <Nav.Link
              href="/SuggestionsForm"
              className="nav-links"
            >
              Suggestions
            </Nav.Link>
            <div className="d-lg-flex align-items-center" style={{ margin: "auto"}}>
            <Select
      options={filteredItems.slice(0, 10)} // Show only the 5 closest suggestions
      onInputChange={setSearchTerm}
      onChange={handleInputChange}
      placeholder="Search for an item"
      isClearable={true}
      aria-label="Search"
      className="search-dropdown align-items-center"
      styles={{
        control: (provided) => ({
          ...provided,
          width: 200, // Set a fixed width for the control
          marginTop: "2px" 
        }),
      }}
    />
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default SiteNavbar;
