import React, { useState } from "react";
import axios from "axios";
import "./SearchResultPage.css"; 

const SuggestionsForm = () => {
  const [email, setEmail] = useState("");
  const [suggestion, setSuggestion] = useState("");
  const [errors, setErrors] = useState({});
  const [submissionMessage, setSubmissionMessage] = useState(null);

  const validateForm = () => {
    const errors = {};

    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = "Invalid email format";
    }

    if (!suggestion.trim()) {
      errors.suggestion = "Suggestion is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await axios.post(
          "https://osrsprices.com/api/send-suggestion",
          {
            email,
            suggestion,
          },
        );

        if (response.status === 200) {
          setEmail("");
          setSuggestion("");
          setSubmissionMessage("Suggestion submitted successfully!");
        } else {
          setSubmissionMessage(
            `Error submitting suggestion. Status: ${response.status}`,
          );
        }
      } catch (error) {
        console.error("Error submitting suggestion:", error);
        setSubmissionMessage(
          "An error occurred while submitting the suggestion. Please try again later.",
        );
      }
    }
  };

  return (
    <div className="search-result-page">
      <h1 style={{ padding: "20px" }}>
        Please send your suggestions or bug reports!
      </h1>
      <div className="result-item">
        {submissionMessage && (
          <div
            style={{ fontSize: "20px", padding: "10px" }}
            className={
              submissionMessage.includes("successfully") ? "success" : "error"
            }
          >
            {submissionMessage}
          </div>
        )}

        <form onSubmit={handleSubmit} className="suggestion-form">
          <div className="form-group">
            <label style={{ paddingRight: "5px" }}>Email:</label>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <div className="error">{errors.email}</div>}
          </div>

          <div className="form-group">
            <label>Suggestions/Bugs:</label>
            <br /> <br />
            <textarea
              placeholder="Enter your suggestion"
              value={suggestion}
              onChange={(e) => setSuggestion(e.target.value)}
              className="suggestionsBox"
            />
            {errors.suggestion && (
              <div className="error">{errors.suggestion}</div>
            )}
          </div>

          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default SuggestionsForm;
