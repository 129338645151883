import React from 'react';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import Home from './Home';
import SiteNavbar from './SiteNavbar';
import SearchResultPage from './SearchResultPage'; 
import HighVolume from './HighVolume';
import HighMargins from './HighMargins';
import SuggestionsForm from './SuggestionsForm';
import ItemListPage from './ItemListPage';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <BrowserRouter>
      <div className="App" style={{ display: 'flex', flexDirection: 'column' }}>
        <SiteNavbar /> 

        <div style={{ flex: 1 }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/item/:searchQuery" element={<SearchResultPage />} />
            <Route path="/HighVolume" element={<HighVolume />} />
            <Route path="/HighMargins" element={<HighMargins />} />
            <Route path="/SuggestionsForm" element={<SuggestionsForm />} />
            <Route path="/ItemListPage" element={<ItemListPage />} />
          </Routes>
        </div>

        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
