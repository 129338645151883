import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import "./App.css";

import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";


const HighMargins = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "High Profit Items - OSRSPrices - Grand Exchange Tracker";
    fetchHighVolumeItems();
  }, []);

  const useStyles = makeStyles({
    root: {
      "& .MuiDataGrid-cell, & .MuiDataGrid-columnHeaderTitle": {
        textOverflow: "clip",
      },
    },
  });

  const fetchHighVolumeItems = () => {
    setLoading(true);
    fetch("https://osrsprices.com/api/getHighProfitMargin")
      .then((response) => response.json())
      .then((data) => {
        const itemsWithIds = data.results.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setData(itemsWithIds);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const LargerTooltips = withStyles({
    tooltip: {
      fontSize: 20,
      padding: 10
    }
  })(Tooltip)

  const columns = [
    {
      field: "ItemImage",
      headerName: "",
      minWidth: 100,
      flex: 0.5,
      renderCell: (params) => (
        <img
          src={"https://oldschool.runescape.wiki/images/" + params.row.imageName}
          alt={params.row.itemName}
          style={{ width: "30px", height: "35px" }}
        />
      ),
      disableColumnMenu: true,
      filterable: false,
    },
    {
      field: "itemName",
      headerName: "Item Name",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <div style={{ cursor: "pointer" }}>
          <LargerTooltips title={params.row.itemName} enterDelay={500}>
            <a
              href={`https://osrsprices.com/item/${encodeURIComponent(
                params.row.itemName,
              )}`}
              style={{ textDecoration: "none", color: "inherit" }}
              onMouseEnter={(e) =>
                (e.target.style.textDecoration = "underline")
              }
              onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
            >
              {params.row.itemName}
            </a>
          </LargerTooltips>
        </div>
      ),
      disableColumnMenu: true,
    },
    {
      field: "highPrice",
      headerName: "High Price",
      type: "number",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => Number(params.value).toLocaleString(),
      cellClassName: "custom-column",
      headerClassName: "custom-column",
      renderCell: (params) => (
        <LargerTooltips title={getTimeElapsed(params.row.highTime)} arrow>
          <div style={{ cursor: "pointer" }}>
            {Number(params.value).toLocaleString()}
          </div>
        </LargerTooltips>
      ),
      disableColumnMenu: true,
    },
    {
      field: "lowPrice",
      headerName: "Low Price",
      type: "number",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => Number(params.value).toLocaleString(),
      cellClassName: "custom-column",
      headerClassName: "custom-column",
      renderCell: (params) => (
        <LargerTooltips title={getTimeElapsed(params.row.lowTime)} arrow>
          <div style={{ cursor: "pointer" }}>
            {Number(params.value).toLocaleString()}
          </div>
        </LargerTooltips>
      ),
      disableColumnMenu: true,
    },
    {
      field: "totalProfit",
      headerName: "Profit(inc tax)",
      type: "number",
      minWidth: 150,
      flex: 1,
      cellClassName: "custom-column",
      headerClassName: "custom-column",
    },
    {
      field: "highAlch",
      headerName: "High Alch Value",
      type: "number",
      minWidth: 150,
      flex: 1,
      cellClassName: "custom-column",
      headerClassName: "custom-column",
    },
    {
      field: "geLimit",
      headerName: "GE Limit",
      minWidth: 150,
      flex: 1,
      type: "number",
      cellClassName: "custom-column",
      headerClassName: "custom-column",
    },
    {
      field: "avgHighPriceVolume",
      headerName: "Selling Quantity(1h)",
      type: "number",
      minWidth: 150,
      flex: 1,
      cellClassName: "custom-column",
      headerClassName: "custom-column",
    },
    {
      field: "avgLowPriceVolume",
      headerName: "Buying Quantity(1h)",
      type: "number",
      minWidth: 150,
      flex: 1,
      cellClassName: "custom-column",
      headerClassName: "custom-column",
    },
  ];

  const getRowClassName = (params) => {
    const profit = params.row.totalProfit;

    const currentTime = Date.now();
    const lowTime = Math.floor(
      (currentTime - params.row.lowTime * 1000) / 1000,
    );
    const highTime = Math.floor(
      (currentTime - params.row.highTime * 1000) / 1000,
    );

    if (lowTime > 7200 || highTime > 7200)
      return "custom-outdated-price-row";
    else if (profit > 0) {
      return "custom-high-price-row"; 
    } else if (profit < 500) {
      return "custom-low-price-row"; 
    }

    return ""; 
  };

  const getTimeElapsed = (epochTime) => {
    const currentTime = Date.now();
    const timeDifferenceInSeconds = Math.floor(
      (currentTime - epochTime * 1000) / 1000,
    );

    if (timeDifferenceInSeconds < 60) {
      return `${timeDifferenceInSeconds} ${getPluralForm(
        timeDifferenceInSeconds,
        "second",
        "seconds",
      )} ago`;
    } else if (timeDifferenceInSeconds < 3600) {
      const timeDifferenceInMinutes = Math.floor(timeDifferenceInSeconds / 60);
      return `${timeDifferenceInMinutes} ${getPluralForm(
        timeDifferenceInMinutes,
        "minute",
        "minutes",
      )} ago`;
    } else {
      const timeDifferenceInHours = Math.floor(timeDifferenceInSeconds / 3600);
      return `${timeDifferenceInHours} ${getPluralForm(
        timeDifferenceInHours,
        "hour",
        "hours",
      )} ago`;
    }
  };

  const getPluralForm = (count, singular, plural) => {
    return count === 1 ? singular : plural;
  };

  return (
    <div style={{ margin: "0 auto", maxWidth: "85vw", maxHeight: "90vw" }}>
      <h1 style={{ padding: "20px" }}>High Profit Margin Items</h1>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div style={{ height: "calc(90vh - 80px)", width: "100%" }}>
          <DataGrid
            rows={data}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            pagination
            components={{
              Toolbar: CustomToolbar,
            }}
            disableColumnMenu
            disableRowSelectionOnClick
            className="custom-data-grid mui-table-container {classes.root}"
            getRowClassName={getRowClassName}
            componentsProps={{ filterPanel: { sx: { maxWidth: "100vw" } } }}
          />
        </div>
      )}
    </div>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

export default HighMargins;
