import React, { useEffect, useState, lazy, Suspense, useMemo  } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./SearchResultPage.css"; 
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Button } from "@material-ui/core";
//import ChartComponent from "./ChartComponent"; // Import the ChartComponent


import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";

const ChartComponent = lazy(() => import("./ChartComponent"));


const SearchResultPage = () => {


  let [graphData, setGraphData] = useState([]);
  const [graphData2, setGraphData2] = useState([]);
  const [graphData3, setGraphData3] = useState([]);
  const { searchQuery } = useParams();
  const [searchResults, setSearchResults] = useState([]);
  const [noDataMessage, setNoDataMessage] = useState("");
  const [dataSet, setData] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post("https://osrsprices.com/api/allData", {
          query: searchQuery,
        });

        // Extract data from the response
        const { searchResults, dailyGraph, hourlyGraph } = response.data;

        // Set state or perform other actions with the retrieved data
        setSearchResults(searchResults.results);

        if(!dataSet){
          setGraphData(dailyGraph);
          setGraphData2(dailyGraph);
          setGraphData3(hourlyGraph);
          setData(true);
        }
        // Set document title if needed
        document.title = searchQuery + " - OSRS Prices - Grand Exchange Tracker";

        setLoading(false); // Set loading to false after data is loaded
      } catch (error) {
        console.error("Error fetching all data:", error);
      }
    };

    fetchData(); // Initial fetch

    const intervalId = setInterval(() => {
      fetchData(); // Fetch data every 2 minutes
    }, 2 * 60 * 1000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [searchQuery, dataSet]);

  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: 18, 
      padding: 10,
    },
  }))(Tooltip);

  const OutdatedWarning = ({ time }) => (
    <div className="outdated-warning">
      <strong>Warning:</strong> Prices are outdated! Some sale data is from over{" "}
      {getTimeElapsed(time)}.
    </div>
  );

  const getWikiLink = useMemo(() => {
    return (id) => {
      return "https://oldschool.runescape.wiki/w/Special:Lookup?type=item&id=" + id;
    };
  }, []); // Empty dependency array indicates it only runs once


  const getAlchProfit = (alchValue, currPrice, natureRuneCost) => {
    const alchProfit =
      alchValue !== null ? alchValue - currPrice - natureRuneCost : 0;
    return alchProfit;
  };

  const checkForOutdatedItems = (params) => {
    const currentTime = Date.now();
    const lowTime = Math.floor(
      (currentTime - params.row.lowTime * 1000) / 1000,
    );
    const highTime = Math.floor(
      (currentTime - params.row.highTime * 1000) / 1000,
    );

    if (lowTime > 7200 || highTime > 7200) {
      return Math.min(params.row.lowTime, params.row.highTime); 
    }

    return null; 
  };

  const getPluralForm = useMemo(() => {
    return (count, singular, plural) => {
      return count === 1 ? singular : plural;
    };
  }, []); // Empty dependency array indicates it only runs once


  const getTimeElapsed = useMemo(() => {
    return (epochTime) => {
      const currentTime = Date.now();
      const timeDifferenceInSeconds = Math.floor(
        (currentTime - epochTime * 1000) / 1000,
      );

      if (timeDifferenceInSeconds < 60) {
        return `${timeDifferenceInSeconds} ${getPluralForm(
          timeDifferenceInSeconds,
          "second",
          "seconds",
        )} ago`;
      } else if (timeDifferenceInSeconds < 3600) {
        const timeDifferenceInMinutes = Math.floor(timeDifferenceInSeconds / 60);
        return `${timeDifferenceInMinutes} ${getPluralForm(
          timeDifferenceInMinutes,
          "minute",
          "minutes",
        )} ago`;
      } else {
        const timeDifferenceInHours = Math.floor(timeDifferenceInSeconds / 3600);

        return `${timeDifferenceInHours} ${getPluralForm(
          timeDifferenceInHours,
          "hour",
          "hours",
        )} ago`;
      }
    };
  }, [getPluralForm]); // Added getPluralForm to the dependency array




  const [timeFormat, setTimeFormat] = useState("day");
  const [enableInteraction, setInteractable] = useState("true");

  const handleButtonClick = (days) => {
    setChartData(days);
  };

  const [xAxisMinMax, setXAxisMinMax] = useState({
    min: moment().subtract(7, 'days').startOf('day'),
    max: moment().clone().subtract(1, 'days').endOf('day'),
  });

  const calculateMinMax = (selectedDays) => {
    const currentTime = moment();
    let min, max;
  
    switch (selectedDays) {
      case 1:
        min = currentTime.clone().subtract(24, 'hours');
        max = currentTime.clone().subtract(1, 'hours');
        break;
      default:
        min = currentTime.clone().subtract(selectedDays, 'days').startOf('day');
        max = currentTime.clone().subtract(1, 'days').endOf('day');
        break;
    }
  
    return { min, max };
  };
  
  const setChartData = (selectedDataset) => {
    const { min, max } = calculateMinMax(selectedDataset);

    setXAxisMinMax({
      min,
      max,
    });

    switch (selectedDataset) {
      case 1:
        if (graphData3 != undefined && graphData3["results"] != undefined && graphData3["results"][0] != null) {
          setTimeFormat("hour");
          setInteractable(false);
          setGraphData(graphData3);
          break;
        } else {
          setTimeFormat("day");
          setInteractable(true);
          setGraphData(graphData2);
          setNoDataMessage("No 24h data available.");
          break;
        }
      default:
        if (graphData2 != undefined && graphData2["results"] != undefined && graphData2["results"][0] != null) {
          setTimeFormat("day");
          setInteractable(true);
          setGraphData(graphData2);
          setNoDataMessage("");
          break;
        }
        else{
          setTimeFormat("day");
          setInteractable(true);
          setGraphData(graphData2);
          setNoDataMessage("No pricing history available. If you think this is an error, please report this item.");
          break;
        }
    }
  };

  
  return (
    <div className="search-result-page">
      <h1 style={{ padding: "20px" }}>{searchQuery}</h1>

      {loading ? (
        <div>Loading data...</div>
      ) : (
        // Render your UI once the data is loaded
        searchResults.map((result) => (
        <div
          key={result.ID}
          className={`result-item ${
            checkForOutdatedItems({ row: result }) ? "outdated-item" : ""
          }`}
        >
          <div className="top-text">
            Prices are automatically updated, tax is automatically deducted from
            the profit, and a warning will appear when prices are outdated.
          </div>
          <br />

          
          {checkForOutdatedItems({ row: result }) > 0 && (
            <OutdatedWarning time={checkForOutdatedItems({ row: result })} />
          )}

          <br />

          <TableContainer
            component={Paper}
            style={{
              boxShadow:
                "0px -4px 8px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Table className="data-table" aria-label="simple table">
              <TableBody>
              <TableRow>
                  <TableCell>
                    <CustomTooltip
                      title={`Last traded: ${getTimeElapsed(result.lowTime)}`}
                      arrow
                      enterTouchDelay={0}
                      leaveTouchDelay={1500}
                    >
                      <div>
                        <strong>Buying Price:</strong>
                      </div>
                    </CustomTooltip>
                  </TableCell>
                  <TableCell>
                    <CustomTooltip
                      title={`Last traded: ${getTimeElapsed(result.lowTime)}`}
                      arrow
                      enterTouchDelay={0}
                      leaveTouchDelay={1500}
                    >
                      <div>
                        {result.lowPrice !== undefined &&
                        result.lowPrice !== null
                          ? result.lowPrice.toLocaleString()
                          : "N/A"}
                      </div>
                    </CustomTooltip>
                  </TableCell>

                  <TableCell>
                    <strong>Buying Quantity (1h):</strong>
                  </TableCell>
                  <TableCell>
                    {result.avgLowPriceVolume !== undefined &&
                    result.avgLowPriceVolume !== null
                      ? result.avgLowPriceVolume.toLocaleString()
                      : "0"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <CustomTooltip
                      title={`Last traded: ${getTimeElapsed(result.highTime)}`}
                      arrow
                      enterTouchDelay={0}
                      leaveTouchDelay={1500}
                    >
                      <div>
                        <strong>Selling Price:</strong>
                      </div>
                    </CustomTooltip>
                  </TableCell>
                  <TableCell>
                    <CustomTooltip
                      title={`Last traded: ${getTimeElapsed(result.highTime)}`}
                      arrow
                      enterTouchDelay={0}
                      leaveTouchDelay={1500}
                    >
                      <div>
                        {result.highPrice !== undefined &&
                        result.highPrice !== null
                          ? result.highPrice.toLocaleString()
                          : "N/A"}
                      </div>
                    </CustomTooltip>
                  </TableCell>
                  <TableCell>
                    <strong>Selling Quantity (1h):</strong>
                  </TableCell>
                  <TableCell>
                    {result.avgHighPriceVolume !== undefined &&
                    result.avgHighPriceVolume !== null
                      ? result.avgHighPriceVolume.toLocaleString()
                      : "0"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Profit:</strong>
                  </TableCell>
                  <TableCell>
                    <span
                      style={{
                        color:
                          result.totalProfit < 0
                            ? "red"
                            : result.totalProfit > 0
                              ? "green"
                              : "black",
                      }}
                    >
                      {result.totalProfit !== undefined &&
                      result.totalProfit !== null
                        ? result.totalProfit.toLocaleString()
                        : "N/A"}
                    </span>
                  </TableCell>
                  <TableCell>
                    <strong>GE Tax:</strong>
                  </TableCell>
                  <TableCell>
                    {result.geTax !== undefined && result.geTax !== null
                      ? result.geTax.toLocaleString()
                      : "N/A"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Alch Profit:</strong>
                  </TableCell>

                  <TableCell>
                    <CustomTooltip
                      style={{
                        color:
                          getAlchProfit(
                            result.highAlch,
                            result.lowPrice,
                            result.alchCost,
                          ) < 0
                            ? "red"
                            : result.totalProfit > 0
                              ? "green"
                              : "black",
                      }}
                      title={`Includes nature rune cost: ${result.alchCost} gp`}
                      arrow
                      enterTouchDelay={0}
                      leaveTouchDelay={1500}
                    >
                      <div>
                        {getAlchProfit(
                          result.highAlch,
                          result.lowPrice,
                          result.alchCost,
                        ).toLocaleString()}
                      </div>
                    </CustomTooltip>
                  </TableCell>

                  <TableCell>
                    <strong>High Alch:</strong>
                  </TableCell>
                  <TableCell>
                    {result.highAlch !== undefined && result.highAlch !== null
                      ? result.highAlch.toLocaleString()
                      : "N/A"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Members:</strong>
                  </TableCell>
                  <TableCell>{result.members ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    <strong>GE Limit:</strong>
                  </TableCell>
                  <TableCell>
                    {result.geLimit !== undefined && result.geLimit !== null
                      ? result.geLimit.toLocaleString()
                      : "N/A"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <a
                      href={getWikiLink(result.ID)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <strong>Wiki Link</strong>
                    </a>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            Price History
          </div>
          <div style={{ marginBottom: "10px" }}>
            <Button
              style={{ fontSize: "12px", padding: "3px 3px" }}
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick(1)}
            >
              24h
            </Button>
            <Button
              style={{ fontSize: "12px", padding: "3px 3px" }}
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick(7)}
            >
              Week
            </Button>
            <Button
              style={{ fontSize: "12px", padding: "3px 3px" }}
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick(30)}
            >
              Month
            </Button>
            <Button
              style={{ fontSize: "12px", padding: "3px 3px" }}
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick(365)}
            >
              Year
            </Button>
            <Button
              style={{ fontSize: "12px", padding: "3px 3px" }}
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick(Math.min(...graphData.results.map(item => item.timestamp)))}
            >
              All
            </Button>
          </div>
          {noDataMessage && (
            <div
              style={{ fontSize: "16px", marginBottom: "10px", color: "red" }}
            >
              {noDataMessage}
            </div>
          )}

          {graphData &&
            graphData["results"] &&
            graphData["results"].length > 0 && (
              <div className="chartBox">
                  <Suspense fallback={<div>Loading chart...</div>}>

                  <ChartComponent graphData={graphData} timeFormat={timeFormat} enableInteraction={enableInteraction} xAxisMinMax={xAxisMinMax} />
                  </Suspense>

              </div>
            )}
        </div>
        ))
      )}
    </div>
  );
};

export default SearchResultPage;
