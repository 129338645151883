import React, { useState } from "react"; // Import useState
import axios from "axios"; // Import axios
import "./SearchResultPage.css"; // Import the CSS file

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  return (
    <div>
      <h1 className="heading-padding">OSRS Prices - Grand Exchange Tracker</h1>
      {buttonClicked ? (
        loading ? (
          <div>Loading...</div>
        ) : (
          <ul>{}</ul>
        )
      ) : (
        <div className="search-result-page">
          <div className="result-item" style={{ fontSize: "20px" }}>
            <h4>
              We're a new Old School Runescape Grand Exchange tracking website!
            </h4>{" "}
            <br />
            <p>
              The purpose of this website is to provide a platform that is both{" "}
              <b>free of charge</b> and user-friendly, offering all the
              essential data required for successful trading and GE flipping.{" "}
              <br />
              <br />
                Explore our item pages curated with up-to-date information
                sourced directly from the{" "}
                <a href="https://oldschool.runescape.wiki/">OSRS Wiki</a>!{" "}
                <br />
              </p>
              <h4>Why choose OSRSPrices?</h4>
              <ul style={{ listStyleType: "none", padding: 0 }}>
                <li>
                  <b style={{ marginRight: "10px" }}>Comprehensive Data:</b>
                  <span>
                    Access all Grand Exchange prices, trading volume, both
                    trading and high alchemy profit, presented in a clear and
                    organized manner.
                  </span>
                </li>
                <br />
                <li>
                  <b style={{ marginRight: "10px" }}>
                    High-Volume and High Profit Items:
                  </b>
                  <span style={{ flex: 1 }}>
                    Discover the best items to trade to maximize your earnings
                    from the GE and boost your in-game gold!
                  </span>
                </li>
              </ul>
              OSRS Prices is completely free, ensuring that all players have
              equal access to powerful trading tools and insights without any
              cost. The site is currently in beta and we will continue to add
              new features.
              <br />
              <br />
              <p>Thank you for choosing OSRSPrices.com.</p>
              <p>Happy Trading!</p>
            
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;