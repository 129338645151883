// Footer.js

import React from 'react';

const Footer = () => {
  return (
    <div style={{ display: 'grid', gridTemplateRows: '1fr auto', minHeight: '20vh' }}>
      <div style={{ padding: '20px' }}>
        {/* Your page content goes here */}
      </div>
      <footer style={{ color: 'white', backgroundColor: '#2B3035', padding: '5px', textAlign: 'center' }}>
        <p>Prices and Images are provided by OSRSWiki and RuneLite</p>
        <p>OSRS Prices is not affiliated with Jagex</p>
      </footer>
    </div>
  );
};

export default Footer;
