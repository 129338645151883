import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css'; // Import the CSS file


const ItemListPage = () => {
  const [predefinedItems, setPredefinedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Fetch the list of predefined items from the backend when the component mounts
    const fetchPredefinedItems = async () => {
      try {
        const response = await axios.get('https://osrsprices.com/api/itemNames');
        const data = response.data; // JSON data containing the "results" array
        const itemNames = data.results.map((item) => item.itemName); // Extract item names from the "results" array
        setPredefinedItems(itemNames);
      } catch (error) {
        console.error('Error fetching predefined items:', error);
      }
    };

    fetchPredefinedItems();
  }, []);

  return (
    <div>
      <h1>All Items</h1>
      <ul className='no-bullets'>
        {predefinedItems.map((item) => (
          <li key={item}>
            <a href={`/item/${encodeURIComponent(item)}`}>{item}</a>
          </li>
        ))}
      </ul>

    
    </div>
  );
};

export default ItemListPage;